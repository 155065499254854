@import '../../../../styles/variables';

.TimeoutModal {
    width: 100%;
    min-width: 30rem;
    padding: 3.4rem 5.2rem;
}

.TimeoutModal h2 {
    margin-bottom: $space-xl;
}

.TimeoutModal .timerImage {
    position: relative;
    width: 100%;
    margin-bottom: $space-xl;

    text-align: center;

    img {
        width: 90px;
        height: 90px;
    }

    &:before, 
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 38%;
        height: 3px;
        transform: translateY(-50%);

        background-color: $color-soft-gray;
        box-shadow: inset 0 1px 0px rgba(136, 128, 122, 0.6);
    }

    &:before {
        left: 0;
    }
    &:after {
        right: 0;
    }
}

.TimeoutModal .buttonWrapper {
    display: flex;
    justify-content: space-around;
}

.restartButton {
    color: $color-almost-black;
    background: linear-gradient(90deg, $color-soft-gray, #ffffff);

    &:before {
        background: #ffffff;
    }
}

.restartButton span:nth-of-type(2):before {
    border-left: 8px solid $color-almost-black;
}