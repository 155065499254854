@import '../../styles/_variables.scss';

.ProgressBar {
    content: '';

    position: relative;
    width: 100%;
    min-height: 2px;
    background: linear-gradient(to bottom, $color-soft-gray, $color-almost-white);

    border-top: 1px solid rgba(68,68,68, 0.2);
    border-right: 1px solid $color-pure-white;
    border-bottom: 1px solid $color-pure-white;
    border-left: 1px solid rgba(68,68,68, 0.2);

    overflow: hidden;
}

.ProgressBar .progress {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    border: 1px solid transparent;
    border-radius: inherit;

    background: linear-gradient(to bottom, $color-jcp-red-light, $color-jcp-red);
    transform-origin: left center;
}
