@import '../../styles/variables.scss';

.HomeSection {
    display: flex;
    align-items: center;
    height: 100%;

    .container {
        margin-left: 44.1%;
        z-index: 1;

        h1 {
            margin: $space-l 0 $space-l * 2.5;
            margin-left: -5px;
        }
    }
}

.HomeSection .backvid {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    video {
        width: 100%;
        height: auto;
    }
}
