@import '../../styles/variables.scss';

.OnScreenKeyboard {
    position: relative;
    width: 100%;
    height: 100%;
}

.OnScreenKeyboard .keyboard {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: $color-soft-gray;
    font-family: $font-regular;
    font-size: 0.8rem;
    padding: $space-l $space-l;
    z-index: 1;

    :global(.hg-button) {
        box-shadow: 0 0 3px -1px rgba(0,0,0,.3);
        height: 2rem;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px;
        background: $color-pure-white;
        border-bottom: 1px solid #b5b5b5;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.OnScreenKeyboard .keyboard.number {
    display: flex;
    flex-wrap: wrap;

    :global(.hg-row) {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    :global(.hg-button) {
        width: 25%;
        height: 100%;
        flex-grow: 0;
    }
}
