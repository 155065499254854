@import '../../styles/variables.scss';

.QuestionSection {

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

}

.QuestionSection div.headerWrapper {

    position: absolute;
    top: $header-height * 0.5;
    left: 50%;

    transform: translateX(-50%);
    
}

.QuestionSection .exit {
    position: absolute;
    top: $header-height * 0.5;
    right: $layout-padding-h;
    transform: translateY(-50%);
}

.QuestionSection .button {

    position: absolute;
    bottom: calc((#{$layout-padding-h} * 2) + #{$inspired-height});
    left: 50%;
    z-index: 10;

    transform: translateX(-50%);

}

.QuestionSection div.paginationWrapper {

    position: absolute;
    bottom: 0;
    left: 50%;

    margin-bottom: $layout-padding-h;

    transform: translateX(-50%);

}
