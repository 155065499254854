@import '../../../../styles/variables';

.ExitModal {
    width: 100%;
    min-width: 37rem;
    padding: 3.4rem 5.2rem;
}

.ExitModal h2 {
    margin-bottom: $space-xxl;
}

.ExitModal .buttonWrapper {
    display: flex;
    justify-content: center;

    button:first-of-type {
        margin-right: $space-l;
    }
}

.cancelButton span:nth-of-type(2):before {
    border-left: 8px solid $color-almost-black;
}
