@import '../../styles/variables.scss';

.BackButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.BackButton .label {
    font-family: $font-regular;
    font-size: 0.6875rem;
    margin-left: $space-s;
}

.BackButton .icon {
    position: relative;
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: linear-gradient(58deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));

    &:before {

        content: "";

        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;

        display: block;
        width: 8px;
        height: 8px;

        border-left: 3px solid $color-jcp-red;
        border-bottom: 3px solid $color-jcp-red;

        transform: translate(-39%, -50%) rotate(45deg);

    }

    &:after {
        content: "";
        position: absolute;
        top: $border-circle-width;
        left: $border-circle-width;
        bottom: $border-circle-width;
        right: $border-circle-width;

        border-radius: inherit;
        background: $color-pure-white;
    }
}
