@import '../../styles/variables.scss';

.FooterBar {

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: $z-index-header-layer;

    width: 100%;
    padding: $layout-padding-h;

    display: flex;
    justify-content: space-between;
    align-items: center;

    pointer-events: none;

}

.FooterBar div.left img.tagline {
    display: block;
    height: $inspired-height;
}

.FooterBar .SoundToggle {

    display: flex;
    align-items: center;

    pointer-events: auto;
    cursor: pointer;

}

.FooterBar .SoundToggle.muted {
    opacity: 0.25;
}

.FooterBar .SoundToggle div {

    width: 1px;
    height: 25px;
    margin: 0 $space-xs;

    background-color: $color-almost-black;

}

.FooterBar .SoundToggle div:nth-child(1),
.FooterBar .SoundToggle div:nth-child(5) {
    transform: scaleY(0.33);
    animation: smallBar 500ms linear alternate infinite forwards;
}

.FooterBar .SoundToggle div:nth-child(2),
.FooterBar .SoundToggle div:nth-child(4) {
    transform: scaleY(0.66);
    animation: mediumBar 500ms linear alternate infinite forwards;
}

.FooterBar .SoundToggle div:nth-child(3) {
    animation: bigBar 500ms linear alternate infinite forwards;
}

.FooterBar .SoundToggle div:nth-child(1) {
    animation-delay: 100ms;
}

.FooterBar .SoundToggle div:nth-child(2) {
    animation-delay: 200ms;
}

.FooterBar .SoundToggle div:nth-child(3) {
    animation-delay: 300ms;
}

.FooterBar .SoundToggle div:nth-child(4) {
    animation-delay: 400ms;
}

.FooterBar .SoundToggle.muted div {
    animation-play-state: paused;
}

@keyframes smallBar {
    from { transform: scaleY(0.33); }
    to   { transform: scaleY(0.1); }
}

@keyframes mediumBar {
    from { transform: scaleY(0.66); }
    to   { transform: scaleY(0.1); }
}

@keyframes bigBar {
    from { transform: scaleY(1); }
    to   { transform: scaleY(0.1); }
}
