.Test {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to left, #e9ddd3, #f8f0ea);

}
