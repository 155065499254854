@font-face {
    font-family: 'Moderat-Bold';
    src: url('/fonts/Moderat-Bold.eot');
    src: url('/fonts/Moderat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Bold.woff2') format('woff2'),
        url('/fonts/Moderat-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Moderat-Condensed-Thin';
    src: url('/fonts/Moderat-Condensed-Thin.eot');
    src: url('/fonts/Moderat-Condensed-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Condensed-Thin.woff2') format('woff2'),
        url('/fonts/Moderat-Condensed-Thin.woff') format('woff');
}

@font-face {
    font-family: 'Moderat-Extended-Medium';
    src: url('/fonts/Moderat-Extended-Medium.eot');
    src: url('/fonts/Moderat-Extended-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Extended-Medium.woff2') format('woff2'),
        url('/fonts/Moderat-Extended-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Moderat-Light';
    src: url('/fonts/Moderat-Light.eot');
    src: url('/fonts/Moderat-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Light.woff2') format('woff2'),
        url('/fonts/Moderat-Light.woff') format('woff');
}

@font-face {
    font-family: 'Moderat-Medium';
    src: url('/fonts/Moderat-Medium.eot');
    src: url('/fonts/Moderat-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Medium.woff2') format('woff2'),
        url('/fonts/Moderat-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Moderat-Regular';
    src: url('/fonts/Moderat-Regular.eot');
    src: url('/fonts/Moderat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Regular.woff2') format('woff2'),
        url('/fonts/Moderat-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Moderat-Thin';
    src: url('/fonts/Moderat-Thin.eot');
    src: url('/fonts/Moderat-Thin.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Moderat-Thin.woff2') format('woff2'),
        url('/fonts/Moderat-Thin.woff') format('woff');
}
