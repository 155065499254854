@import '../../styles/variables.scss';

.PersonaSection {
    display: flex;
    align-items: center;
    height: 100%;
}

.PersonaSection .backvid {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    video {
        width: 100%;
        height: auto;
    }
}


.PersonaSection .container {
    margin-left: 44.1%;
    width: 55.9%;
    z-index: 1;

    h1 {
        margin-left: -5px;
        margin-bottom: $space-m;
        padding-right: $space-xl;

        font-size: 2.5rem;
        letter-spacing: -1px;
        line-height: 1.15;
    }

    .description {
        width: 65%;
        line-height: 1.6;
        margin-bottom: $space-l;
    }
}

.PersonaSection .loader {
    display: inline-flex;
    justify-content: flex-start;

    color: $color-jcp-red;
    font-family: $font-medium;
    font-size: 0.8rem;
    letter-spacing: -0.5px;
    text-transform: uppercase;
    overflow: hidden;

    &:before, &:after {
        content: '';

        position: absolute;
        left: 3px;
        bottom: 0;
        right: 3px;
        height: 3px;

        border-top: 1px solid rgba(68,68,68, 0.2);
        border-bottom: 1px solid $color-pure-white;
        border-radius: 2.5px;
        background: $color-soft-gray;
    }

    &:after {
        transform-origin: left center;
        background: $color-jcp-red;
        border-color: transparent;
        animation-delay: 5s;
        animation-duration: 9s;
        animation-name: loadin;
        animation-timing-function: linear;
    }
}

.PersonaSection .loader h4 {
    position: relative;
    padding-bottom: $space-m;
}

@keyframes loadin {
    from { transform: scaleX(0); }
    to { transform: scaleX(1); }
}
