@import '../../styles/variables.scss';

.ExitButton {

    display: flex;
    align-items: center;

    cursor: pointer;

}

.ExitButton .label {

    font-family: $font-regular;
    font-size: 0.6875rem;

    margin-right: $space-s;

    transform: translateY(-2px);

}

.ExitButton .icon {

    position: relative;

    width: $button-circle-height;
    height: $button-circle-height;

    border-radius: 50%;
    background: linear-gradient(58deg, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
}

.ExitButton .icon:after {

    content: '';

    position: absolute;
    top: $border-circle-width;
    right: $border-circle-width;
    bottom: $border-circle-width;
    left: $border-circle-width;

    border-radius: inherit;
    background-color: $color-pure-white;

}

.ExitButton .cross {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

}

.ExitButton .cross:before,
.ExitButton .cross:after {

    content: '';

    position: relative;
    z-index: 5;

    display: block;

    width: 16px;
    height: 3px;

    background-color: $color-jcp-red;

}

.ExitButton .cross:before {
    transform: translateY(1.5px) rotate(45deg);
}

.ExitButton .cross:after {
    transform: translateY(-1.5px) rotate(-45deg);
}
