@import '../../styles/variables.scss';

.HeaderTitle {

    display: flex;
    flex-direction: column;

    color: $color-jcp-red;

}

.HeaderTitle.left {
    align-items: flex-start;
}

.HeaderTitle.center {
    align-items: center;
}

.HeaderTitle h5 {
    padding-bottom: $space-l;
}

.HeaderTitle span.divider {

    display: block;

    width: 55px;
    height: 2px;

    background-color: currentColor;

}
