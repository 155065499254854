@import '../../styles/_variables.scss';

.CountryCodeSelector {
    position: relative;
    width: 100%;
    height: 100%;
}

.CountryCodeSelector .label {
    width: 100%;
    height: inherit;
    padding-right: 35px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: $font-light;
    font-size: 1rem;

    cursor: default;
}

.CountryCodeSelector .select {
    position: absolute;
    top: 100%;
    left: 0;
    height: 200px;
    width: 30vw;
    min-width: 230px;

    display: flex;
    flex-direction: column;
    overflow: auto;

    font-family: $font-light;
    font-size: 0.7rem;

    background: $color-very-light-greige;
    border: 1px solid $color-mid-greige;

    z-index: 999;
}

.CountryCodeSelector .select {

    &:hover {
        background: $color-light-greige;
        cursor: pointer;
    }
}


.CountryCodeSelector .arrow {
    display: block;
    content: '';
    width: 35px;
    height: 100%;
    margin: 0;
    border: 0;
    border-right: 2px solid $color-soft-gray;
    opacity: 0.7;
    transition: color 0.1s;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
}

.CountryCodeSelector .arrow:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    border-width: 0;
    transition: color 0.1s;
    width: 8px;
    height: 8px;
    border-left: 3px solid $color-jcp-red;
    border-bottom: 3px solid $color-jcp-red;
    transform: translate(-59%, -50%) rotate(-45deg);
}
