@import '../../styles/variables.scss';

div.Selectoid {

    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;

    pointer-events: none;

}

div.Selectoid.hasFingers {
    align-items: flex-end;
}

div.Selectoid div.answer {

    display: flex;
    flex-direction: column;
    align-items: center;

    width: 16%;
    margin: 0 $space-l;

    transition: opacity $transition-duration $cubic-bezier;

}

div.Selectoid div.answer div.card {

    position: relative;

    width: 100%;
    height: 0;
    padding-top: 105%;

    border-radius: 20px;

    background: linear-gradient(to top right, $color-sand 25%, $color-light-cream);
    box-shadow: 0 0 1px rgba(0, 0, 0, .08);

}

div.Selectoid div.answer div.card div.image {

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    width: calc(100% - 18px);
    height: calc(100% - 18px);

    border-radius: 15px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .08);

    background-size: cover;
    background-position: center center;

}

div.Selectoid div.answer img.cutout {
    max-width: 100%;
    max-height: 25vh;
}

div.Selectoid div.answer h3 {

    margin-top: $space-m;

    color: $color-almost-black;
    text-align: center;
    white-space: nowrap;

}

div.Selectoid div.answer div.card,
div.Selectoid div.answer h3 {
    pointer-events: none;
}

div.Selectoid div.answer div.finger,
div.Selectoid div.answer div.finger div.outer,
div.Selectoid div.answer div.finger div.inner {

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

}

div.Selectoid div.answer div.finger div.outer,
div.Selectoid div.answer div.finger div.inner {

    width: calc(100% - 14px);
    height: calc(100% - 14px);

    transition: background $transition-duration $cubic-bezier;

}

div.Selectoid div.answer div.finger {

    width: 80px;
    height: 80px;
    margin-top: $space-m;
    margin-bottom: -$space-s;

    background: linear-gradient(to bottom, $color-almost-white 10%, rgba(255, 255, 255, 0));
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.02);

}

div.Selectoid div.answer div.finger div.outer {
    background: linear-gradient(to bottom left, $color-sand 20%, $color-light-greige);
}

div.Selectoid div.answer.selected div.finger div.outer {
    background: linear-gradient(to bottom left, #e72231 30%, $color-jcp-red-light);
}

div.Selectoid div.answer div.finger div.inner {
    position: relative;
    background: linear-gradient(to bottom left, #f3eee6, $color-sand);
}

div.Selectoid div.answer.selected div.finger div.inner {
    background: linear-gradient(to bottom left, $color-jcp-red-light, #ec2634 75%);
}

div.Selectoid div.answer div.finger div.inner:after {

    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;

    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(0, 0, 0, 0.08), rgba(255, 255, 255, 0) 60%);

}

div.Selectoid div.answer div.finger div.inner img {

    position: relative;
    z-index: 10;

    width: 40%;

}
