
@import '../../styles/mediaqueries.scss';
@import '../../styles/variables.scss';

$button-font-size: 0.85rem;
$button-side-padding: 44px;

$button-background-dark: #ba1623;
$button-background-light: #fe524a;

.Button {

    outline: none;

    position: relative;
    height: $button-height;
    min-width: 255px;
    padding: 0 $button-side-padding;
    background: linear-gradient(00deg, $color-jcp-red, $color-jcp-red-light);
    border-radius: $button-height;

    display: flex;
    justify-content: center;
    align-items: center;

    color: $color-pure-white;
    font-family: $font-bold;
    font-size: $button-font-size;
    text-transform: uppercase;
    letter-spacing: 0;

    border: none;
    z-index: 0;

    cursor: pointer;

    transition: color 500ms ease, transform $transition-duration $transition-timing;

    @include desktop-micro {
        min-width: 155px;
    }

}

.Button.lg {
    height: $button-height-lg;
}

.Button:before,
.Button:after {

    content: "";

    position: absolute;
    border-radius: inherit;

}

.Button:before {

    top: $border-width;
    left: $border-width;
    bottom: $border-width;
    right: $border-width;
    z-index: -2;

    background: linear-gradient(180deg, $color-jcp-red, $color-jcp-red-light);

}

.Button:after {

    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    z-index: -1;

    background: linear-gradient(180deg, $color-pure-white, $color-very-light-greige);

    opacity: 0;
    transition: opacity 500ms ease;

}

.Button:active {
    transform: scale(0.9);
}

.Button .label {
    transform: translateY(-1px);
}

.Button .caret {

    position: relative;
    width: 6px;
    height: 6px;
    margin-left: $space-m;

    &:before {

        content: "";

        position: absolute;

        width: 0;
        height: 0;

        top: 50%;
        right: 0;
        width: 0;
        height: 0;
        transform: translateX(0) translateY(-50%);

        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid $color-pure-white;
        transition: border-color 500ms ease;

    }
}

.Button:disabled {

    cursor: default;
    color: rgba(0, 0, 0, 0.1);

    &:after {
        opacity: 1;
    }

    .caret:before {
        border-left-color: rgba(0, 0, 0, 0.1);
    }

}

.Button.black {
    color: $color-almost-black;
    background: linear-gradient(0deg, $color-soft-gray, $color-pure-white);

    &:before {
        background: linear-gradient(180deg, $color-soft-gray, $color-pure-white);
    }

    .caret:before {
        border-left: 6px solid $color-pure-black;
    }
}
