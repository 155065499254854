@import '../../styles/variables.scss';

.PersonaTitle {
    position: absolute;
    top: $header-height * 0.3;
    left: 50%;
    transform: translate(-50%, -10%);

    display: flex;
    align-items: center;
}

.PersonaTitle .image {
    width: 50px;
    height: 50px;
    margin-right: $space-m;

    background-color: white;
    background-size: cover;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);

    border-radius: 50%;
}

.PersonaTitle .header {
    font-family: $font-regular;
    font-size: 0.8rem;
}
