@import '../../styles/_variables.scss';

.PincodeVideo {
    position: relative;
}

.PincodeVideo .buttonContainer {
    position: absolute;
    bottom: 7vh;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
}

.PincodeVideo .buttonContainer .button {
    font-size: 0.65rem;
    min-width: 50%;
}

.PincodeVideo video {
    height: 70vh;
}

.PincodeVideo.sp .buttonContainer .button {
    font-size: 0.5rem;
}

.PincodeVideo .replay {
    position: absolute;
    right: 9%;
    top: 1%;
    display: flex;
    align-items: center;

    font-family: $font-regular;
    font-size: 0.6875rem;
}

.PincodeVideo .replay .icon {
    position: relative;

    width: calc(#{ $button-circle-height } + 1vh);
    height: calc(#{ $button-circle-height } + 1vh);
    margin-right: $space-s;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: linear-gradient(238deg, $color-light-greige, $color-very-light-greige 33%);
}

.PincodeVideo .replay .icon:after {
    content: '';

    position: absolute;
    top: $border-circle-width;
    right: $border-circle-width;
    bottom: $border-circle-width;
    left: $border-circle-width;

    border-radius: inherit;
    background-color: $color-pure-white;

    z-index: 0;
}

.PincodeVideo .replay .icon img {
    position: relative;
    width: 50%;
    z-index: 1;
}

.PincodeVideo .replay span {
    position: absolute;
    left: 100%;
}
