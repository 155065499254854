@import '../../styles/variables.scss';

.StyleBoardSection {
    height: 100%;
}

.StyleBoardSection .exit {

    position: absolute;
    top: $header-height * 0.5;
    right: $layout-padding-h;

    transform: translateY(-50%);

}

.StyleBoardSection .header {
    position: relative;
    z-index: 5;
}

.StyleBoardSection .container {
    width: 100%;
    height: 100%;

    overflow-x: auto;
    overflow-y: hidden;
    z-index: 0;

    mask-image: linear-gradient(
        to right,
        transparent $layout-padding-h * 0.25,
        $color-pure-black $layout-padding-h * 1.25,
        $color-pure-black calc(100% - #{$layout-padding-h} * 1.25),
        transparent calc(100% - #{$layout-padding-h} * 0.25)
    );
}

.StyleBoardSection .container .scroller {
    position: relative;

    height: calc(100% + #{ $space-l });
    padding: 0 $layout-padding-h $space-l;

    overflow-x: scroll;
    overflow-y: hidden;

    -webkit-overflow-scrolling: touch;

    display: flex;
}

.StyleBoardSection .container .scroller .contentWrapper {
    // height: 100%;
    display: flex;
    cursor: grab;
}

.StyleBoardSection .pinboard {
    display: flex;
    align-self: center;
    height: calc((100% - #{ $space-l }) * 0.75);
}

.StyleBoardSection .pincode {
    position: relative;
    width: 100vw;
    height: auto;
}

.StyleBoardSection .pincode .headers {
    position: absolute;
    top: 12.5%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    text-align: center;
}

.StyleBoardSection .pincode .video {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}

.StyleBoardSection .scrollbar {

    position: absolute;
    // right: calc(#{$layout-padding-h} + 45px + #{$space-l});
    right: $layout-padding-h;
    bottom: $layout-padding-h;

    width: 50%;

    display: flex;
    align-items: center;

}

.StyleBoardSection .scrollbar .progressbar {
    width: 100%;
    height: 5px;
}

.StyleBoardSection .scrollbar .clicker {

    width: 40px;

    margin-left: $space-l;
    transition: transform 200ms ease-in-out;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }

    img {
        width: 100%;
    }
}
