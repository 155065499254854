@import '../../styles/variables.scss';

.Checkbox {
  position: relative;
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  
  border-radius: 50%;
  overflow: hidden;
  
  vertical-align: middle;
  
  background: linear-gradient(-180deg, $color-light-cream 40%, $color-light-greige 100%);
  // box-shadow: 0px 0px 3px rgba(136, 128, 122, 0.8);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    background: linear-gradient(-180deg, $color-jcp-red-light 40%, rgb(171, 23, 35) 100%);
    transition: 150ms opacity ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 4px solid rgba(136, 128, 122, 0.1);
    transition: 200ms border-color ease-in-out;
    border-radius: 50%;
    box-sizing: border-box;
  }
}

.Checkbox input[type=checkbox] {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  padding: $space-l;
}

.Checkbox svg {
  position: absolute;
  top: 50.5%;
  left: 49%;
  height: 52%;
  
  fill: none;
  
  stroke: white;
  stroke-width: 5px;
  stroke-linecap: butt;
  opacity: 1;

  transform: translate(-50%, -50%);
}

.Checkbox.checked:before {
  opacity: 1;
  // background: linear-gradient(-180deg, $color-jcp-red-light 40%, rgb(171, 23, 35) 100%);
}

.Checkbox.checked:after {
  border: 4px solid rgba(199, 31, 45, 0.7);
}

.Checkbox.checked svg {
  stroke: white;
}
