@import '../../../../styles/mediaqueries';
@import '../../../../styles/variables';

.PinboardLinkForm > div {
    transition: 250ms opacity ease-in-out;
}

.PinboardLinkForm .hidden {
    opacity: 0;
    display: none;
}

.PinboardLinkForm .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-width: 35rem;
    padding: 3.4rem 5.2rem;
}

.PinboardLinkForm .form > *:not(:last-child) {
    // margin-bottom: $space-l;

    @include screen-min-less {
        margin-bottom: $space-l;
    }
}

.PinboardLinkForm h2.title {
    color: $color-almost-black;
    font-size: 1.27rem;
    margin-bottom: $space-xl;
}

.PinboardLinkForm .form .tab {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin-bottom: $space-xl;

    text-transform: uppercase;
    font-weight: 700;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background-color: $color-light-greige;
        box-shadow: inset 0 1px 0px rgba(136, 128, 122, 0.6);
        transition: 250ms background-color ease-in-out;
        z-index: -1;
    }
}

.PinboardLinkForm .form .tab .tabBtn {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    flex-grow: 1;

    padding: $space-inset-s;
    padding-bottom: $space-m;

    font-size: 0.7rem;
    text-align: center;
    line-height: 1.5;
    color: $color-light-greige;

    transition: 250ms color ease-in-out;


    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        transition: 250ms background-color ease-in-out;
    }

    &.active {
        color: $color-jcp-red;

        &:after {
            background-color: $color-jcp-red;
            box-shadow: none;
        }
    }
}

.PinboardLinkForm .form input {
    height: calc(0.3rem * 6);
    width: 100%;
    padding: 0 $space-l;

    outline: none;
    appearance: none;

    border: 1px solid $color-soft-gray;
    border-top: 0;
    border-bottom: 0px solid $color-soft-gray;
    border-radius: calc(0.3rem * 6);

    font-family: $font-light;
    font-size: 1rem;
    transition: border 0.1s;

    box-shadow: 0px 4px 0px $color-soft-gray;

    &::-webkit-input-placeholder {
        color: $color-soft-gray;
    }
}

.PinboardLinkForm .form {
    :global(.react-phone-number-input__icon) {
        transform: translateY(-2px);
    }

    :global(.react-phone-number-input__country-select-arrow) {
        display: block;
        content: '';
        width: 50px;
        height: 100%;
        margin: 0;
        border: 0;
        border-right: 2px solid $color-soft-gray;
        opacity: 0.7;
        transition: color 0.1s;
        position: relative;
    }

    :global(.react-phone-number-input__country-select-arrow:after) {
        position: absolute;
        left: 50%;
        top: 50%;
        content: '';
        border-width: 0;
        transition: color 0.1s;
        width: 8px;
        height: 8px;
        border-left: 3px solid $color-jcp-red;
        border-bottom: 3px solid $color-jcp-red;
        transform: translate(-59%, -78%) rotate(-45deg);
    }

    :global(.react-phone-number-input) {
        width: 100%;
        height: calc(0.3rem * 6.05);
        padding: 1px $space-m;

        background-color: white;

        border: 1px solid $color-soft-gray;
        border-top: none;
        border-radius: calc(0.3rem * 6);

        box-shadow: 0px 3px 0px $color-soft-gray;

        input {
            background: none;
            padding: 0;
            border: 0;
            border-radius: 0;
            box-shadow: none;
        }
    }
}


// Privacy Data
.PinboardLinkForm .privacyBack {
    position: absolute;
    top: $space-l;
    left: $space-l;
}

.PinboardLinkForm .privacy {
    position: absolute;
    top: 3.4rem;
    left: 50%;
    width: 100%;
    height: 80%;
    transform: translate(-50%, 0%);
    padding: 0 $space-l + 21.5px 0 $space-xl;
}

.PinboardLinkForm #privacy h2 {
    text-align: left;
    padding: 0 $space-xl 0 26px;
    margin-bottom: $space-m;
}

.PinboardLinkForm .privacy .privacyContent {
    position: relative;
    width: 100%;
    height: 87%;
    padding: 0.5rem $space-xl 0.5rem 26px;
    overflow-x: hidden;
    overflow-y: scroll;

    color: $color-mid-greige;
    font-size: 0.7rem;
    line-height: 1.7;
    mask-image: linear-gradient(to top, transparent 0%, black 10%, black 94%, transparent 100%), linear-gradient(to right, transparent 98%, black 0%);
}

// Privacy Content Styling
.PinboardLinkForm .privacy .privacyContent {
    > div {
        padding-bottom: 0.5rem;
    }

    em {
        font-style: italic;
    }

    h1 {
        font-size: 1rem;
        letter-spacing: 0;
        margin-bottom: 0.5rem;
    }
    p {
        margin-bottom: 0.5rem;

        strong {
            font-family: 'Moderat-Bold', sans-serif;
            font-weight: 700;
        }
    }

    ul {
        list-style-type: disc;
        padding-inline-start: 40px;
        margin-bottom: 0.5rem;
    }

    ol {
        list-style-type: decimal;
        padding: 0 $space-m;
        margin-bottom: 0.5rem;
        font-family: 'Moderat-Bold', sans-serif;
        font-weight: 700;

        p, ul {
            font-family: 'Moderat-Light', sans-serif;
            font-weight: 400;
        }

        li::marker {
            font-weight: bold;
        }
    }
}

.PinboardLinkForm .privacyContent::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    z-index: 100;
}

/* Handle */
.PinboardLinkForm .privacyContent::-webkit-scrollbar-thumb {
    background: $color-jcp-red;
    border-radius: 8px;
}

/* Track */
.PinboardLinkForm .privacyContent::-webkit-scrollbar-track {
    background: $color-light-greige;
    border-radius: 8px;
}
