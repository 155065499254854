@import '../../styles/mediaqueries.scss';
@import '../../styles/variables.scss';

$button-color-light: #f34037;
$button-color-dark: #d01d29;

.PinboardClickTile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10% 10%;
    height: 100%;
    width: 100%;

    background: $color-jcp-red;
    color: $color-pure-white;
    font-family: $font-medium;
    font-size: 0.9rem;
    letter-spacing: 0px;
    line-height: 1.3;
}

.PinboardClickTile .button {
    padding: 0;
    background: linear-gradient(0deg, $button-color-dark, $button-color-light);
    min-width: initial;

    font-size: 0.6rem;

    &:before {
        background: linear-gradient(180deg, $button-color-dark, $button-color-light);
    }
}

.PinboardClickTile.en .button .buttonContent img {
    margin-right: $space-s;
}

.PinboardClickTile.sp .button {
    font-size: 0.45rem;
}

.PinboardClickTile .button .buttonContent {
    display: flex;
    align-items: center;

    img {
        height: 27px;
        margin-left: $space-s;
    }
}
