@import '../../styles/variables';

.privacyBtn {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-weight: 400;
    font: inherit;
    text-decoration: none;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 3px;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid $color-almost-black;
        transition: 50ms all ease-in-out;
    }

    &:hover, &:focus {
        &:after {
            border-bottom: 1px solid transparent;
        }
    }
}

.checkboxWrapper {
    width: 100%;
    max-width: 23rem;
}

.checkbox {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: $space-m;
    flex-shrink: 0;
}

.privacyWrapper > label {
    white-space: pre;
}

.privacyWrapper, .terms {
    display: flex;
    align-items: center;
    font-size: 0.6rem;
    line-height: 1.5;
    margin-bottom: $space-l;
}

.privacyLabel {
    width: 100%;
    margin-bottom: $space-l;
}

.privacyLabel p.error,
.privacyLabel p.success {
    margin-top: $space-m;
    margin-bottom: -$space-m;
    padding: 0 $space-l;
    
    font-family: Arial, sans-serif;
    font-size: 0.55rem;
    letter-spacing: 0.02rem;
    line-height: 1.5;

    text-align: left;
    color: $color-jcp-red;
}

.privacyLabel p.success {
    color: green;
}


.hidden {
    display: none;
}
