@import '../../styles/variables.scss';

.Modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // max-width: $main-container-width;
    // max-height: $main-container-height;
    // background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
}

.Modal .innerWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 1rem;
    background-color: $color-light-cream;

    background: linear-gradient(138deg, $color-diet-cream 55%, $color-very-light-greige 100%);
    box-shadow: 0px 0px 55px rgba(136, 128, 122, 0.2);

    transition: transform 250ms ease-in-out;
}

.Modal .innerWrapper h2 {
    text-align: center;
}

.Modal .innerWrapper .closeBtn {
    position: absolute;
    top: $space-l;
    right: $space-l;
}

.Modal .innerWrapper.openKeyboard {
    transform: translate( -50%, -80%);
}

.Modal.fadeOut {
    opacity: 0;
}

.Modal .keyboard {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
}
