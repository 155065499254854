@import './reset.scss';
@import './typography.scss';
@import './mediaqueries.scss';
@import './variables.scss';

html, body, #root {
    width: 100%;
    height: 100%;
    font-size: calc(17px + (32 - 17) * ((100vw - 1024px) / (1920 - 1024)));

    @include screen-min-less {
        font-size: 17px;
    }

    @include screen-max-more {
        font-size: 32px;
    }
}

body {

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: $color-almost-black;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    text-decoration: none;
}

* {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}
