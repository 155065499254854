@import '../../styles/variables.scss';

div.Pagination {
    display: flex;
}

div.Pagination div.page {

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: $color-cream;
    box-shadow: 0 3px 0 #e6e1dc;

}

div.Pagination div.page div.dot {

    width: 8px;
    height: 8px;
    margin-top: 7.5%;

    border-radius: 50%;
    background-color: $color-mid-grey;

}
