@import '../../styles/mediaqueries.scss';
@import '../../styles/variables.scss';

.Pinboard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 100%;

    writing-mode: vertical-lr;

    padding: 15px 0;

}

.Pinboard > div {
    height: 80px;
    width: calc(220px + (310 - 220) * ((100vw - 1024px) / (1920 - 1024)));
    margin-right: $space-l;

    background: $color-jcp-red;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    border-radius: 5px;

    overflow: hidden;
    writing-mode: horizontal-tb;

    box-shadow: 0 5px 10px rgba(0, 0, 0, .15);

    @include screen-min-less {
        width: 220px;
    }

    @include screen-max-more {
        width: 310px;
    }
}

.Pinboard > div:nth-of-type(8n + 1),
.Pinboard > div:nth-of-type(8n + 6) {
    height: calc(65% - #{$space-l} * 0.5);
}

.Pinboard > div:nth-of-type(8n + 2),
.Pinboard > div:nth-of-type(8n + 5) {
    height: calc(35% - #{$space-l} * 0.5);
}

.Pinboard > div:nth-of-type(8n + 3),
.Pinboard > div:nth-of-type(8n + 4),
.Pinboard > div:nth-of-type(8n + 7),
.Pinboard > div:nth-of-type(8n + 8) {
    height: calc(50% - #{$space-l} * 0.5);
}
