@import '../../styles/variables.scss';

.App {
    width: 100%;
    height: 100%;

    font-family: $font-light;

    // @TODO: Update after development
    background: $color-cream;
}

.App .container {
    position: relative;

    // @TODO Change to gradient.
    background: $color-cream;

    // @TODO: Update after development
    width: 100%;
    height: 100%;

    transition: 500ms filter ease-out;

    & > section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.blur {
        filter: blur(10px) brightness(1.05);
    }
}

.App h1 {
    font-family: $font-bold;
    font-size: 3.125rem;
    white-space: pre-line;
    letter-spacing: -0.0375em;
}

.App h2 {
    font-family: $font-medium;
    font-size: 1.5625rem;
    white-space: pre-line;
}

.App h3 {
    font-family: $font-regular;
    font-size: 1rem;
    white-space: pre-line;
    letter-spacing: -0.025rem;
}

.App h4 {
    color: $color-jcp-red;
    font-family: $font-medium;
    font-size: 0.75rem;
    letter-spacing: -0.5px;
    line-height: 1.4;
}

.App h5 {

    position: relative;

    font-family: $font-medium;
    font-size: 0.7rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: -0.025em;
    color: $color-jcp-red;

}

.App p {
    white-space: pre-line;
}