@import '../../styles/variables.scss';

.HeaderBar {

    position: relative;
    display: flex;
    align-items: center;
    height: $header-height;
    padding: 0 $layout-padding-h;
    pointer-events: none;
    z-index: $z-index-header-layer;

    .logo {
        width: 9.5%;
        transform: translateX(-10px) translateY(10px);
    }
}
