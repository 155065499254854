@import '../../styles/variables.scss';

.Question {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    /* HeaderTitle offset + HeaderTitle font-size + HeaderTitle margin-bottom + ... . */
    padding: calc((#{$header-height} * 0.5) + 0.7rem + #{$space-l} + #{$space-m}) 0 calc((#{$layout-padding-h} * 2) + #{$inspired-height} + #{$button-height});

    width: 100%;
    height: 100%;

    h2 {

        text-align: center;
        letter-spacing: -0.025em;
        line-height: 1.2;

        opacity: 0;

    }

    .answers {

        position: relative;
        z-index: 5;

        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

    }

}
