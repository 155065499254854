@import '../../styles/variables.scss';

div.Slider {

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

}

div.Slider div.canvasContainer {
    position: relative;
}

div.Slider div.knob,
div.Slider div.knob:after,
div.Slider div.dest,
div.Slider div.dest:before {
    position: absolute;
    border-radius: 50%;
}

div.Slider div.knob,
div.Slider div.dest {
    pointer-events: none;
}

div.Slider div.arrow,
div.Slider div.dest,
div.Slider div.dest:before,
div.Slider div.dest:after {
    position: absolute;
    top: 50%;
}

div.Slider div.knob:after,
div.Slider div.dest:before,
div.Slider div.dest:after {
    transform: translateX(-50%) translateY(-50%);
}

div.Slider div.knob {

    top: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(to bottom left, $color-jcp-red 30%, $color-jcp-red-light);
    box-shadow: 0 0 25px rgba(0, 0, 0, .2);

}

div.Slider div.knob:after {

    content: '';

    top: 50%;
    left: 50%;

    width: 60%;
    height: 60%;

    background: linear-gradient(to bottom left, $color-jcp-red-light, $color-jcp-red 75%);

}

div.Slider div.knob img {

    position: relative;
    z-index: 5;

    width: 30%;

    -webkit-touch-callout: none;
    user-select: none;

}

div.Slider div.track {

    display: flex;

    position: absolute;
    top: 50%;
    z-index: -1;

    transform: translateY(-50%);

    height: 6px;

}

div.Slider div.track div.left,
div.Slider div.track div.right {

    width: 50%;
    height: 100%;

    background: linear-gradient(to bottom, $color-soft-gray, $color-almost-white);

    border-top: 1px solid rgba(68,68,68, 0.25);
    border-bottom: 1px solid $color-pure-white;

}

div.Slider div.track div.left {
    mask-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1) 35%);
}

div.Slider div.track div.right {
    mask-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 1) 35%);
}

div.Slider div.track div.ball {

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);

    background-color: $color-jcp-red;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);

    border-radius: 50%;

}

div.Slider div.arrow {
    transform: translateY(-50%) rotate(45deg);
    border: 2px solid $color-jcp-red;
}

div.Slider div.arrow.left {

    border-top: none;
    border-right: none;

    animation: pulseLeft 3s ease infinite 0.5s;

}

div.Slider div.arrow.right {

    border-bottom: none;
    border-left: none;

    animation: pulseRight 3s ease infinite 0.5s;

}

@keyframes pulseLeft {
    0% {
        opacity: 0;
        transform: translateY(-50%) translateX(0) rotate(45deg);
    }
    15% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-50%) translateX(-600%) rotate(45deg);
    }
}

@keyframes pulseRight {
    0% {
        opacity: 0;
        transform: translateY(-50%) translateX(0) rotate(45deg);
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(-50%) translateX(600%) rotate(45deg);
    }
}

div.Slider div.dest {
    transform: translateY(-50%);
    background: linear-gradient(to bottom, $color-pure-white 40%, rgba(255, 255, 255, 0));
}

div.Slider div.dest:before,
div.Slider div.dest:after {

    content: '';

    left: 50%;
    border-radius: 50%;

}

div.Slider div.dest:before {

    width: 72%;
    height: 72%;

    background-color: #f7d7d9;

}

div.Slider div.dest:after {

    width: 20%;
    height: 20%;

    background-color: $color-jcp-red;

}

div.Slider .callToAction {

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-jcp-red;
    white-space: nowrap;
    text-align: center;

    margin-top: 15px;

}

div.Slider .callToAction span.ring {

    display: inline-block;

    width: 8px;
    height: 8px;

    border-radius: 50%;
    border: 1px solid $color-jcp-red;

}

div.Slider .callToAction span.ring:nth-of-type(-n + 2) {
    margin-right: 5px;
}

div.Slider .callToAction span.ring:nth-of-type(n + 4) {
    margin-left: 5px;
}

div.Slider .callToAction span.ring:nth-of-type(1),
div.Slider .callToAction span.ring:nth-of-type(6) {

    width: 4px;
    height: 4px;

    opacity: .33;

}

div.Slider .callToAction span.ring:nth-of-type(2),
div.Slider .callToAction span.ring:nth-of-type(5) {

    width: 6px;
    height: 6px;

    opacity: .66;

}

div.Slider .callToAction span.ring:nth-of-type(3) {
    margin-right: 10px;
}

div.Slider .callToAction span.ring:nth-of-type(4) {
    margin-left: 10px;
}

div.Slider div.images {

    display: flex;
    justify-content: space-between;

    width: 100%;

}

div.Slider div.images div.imageContainer {

    position: relative;

    background: linear-gradient(to top right, $color-sand 25%, #f9f6f0);
    box-shadow: 0 0 1px rgba(0, 0, 0, .08);

    border-radius: 50%;
    cursor: pointer;

    pointer-events: none;

    &.one { transform: translateX(calc(100% / 3)) translateX(-50%); }
    &.two { transform: translateX(calc(-100% / 3)) translateX(50%); }

}

div.Slider div.images div.image {

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;

    transform: translateX(-50%) translateY(-50%);

    width: calc(100% - 18px);
    height: calc(100% - 18px);

    border-radius: 50%;

    background-position: center center;
    background-size: cover;

}

div.Slider .answers {

    position: absolute;

    color: $color-almost-black;

    &.one { transform: translateX(-50%); }
    &.two { transform: translateX(50%); }

}
