@import '../../styles/_variables.scss';

.SmsForm {
    position: relative;
}

.SmsForm .countryCode {
    position: absolute;
    top: 0;
    left: $space-m;
    height: 100%;
    width: 16%;
}

.SmsForm .input {
    padding-left: calc(16% + #{ $space-m } * 2) !important;
}
