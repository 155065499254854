@import '../../styles/variables.scss';

.LanguageSelector {
    position: absolute;
    top: $header-height * 0.5;
    right: $layout-padding-h;
    transform: translateY(-50%);
    z-index: $z-index-header-layer;

    ul {
        list-style: none;
        display: flex;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            margin-left: $space-m;

            background: $color-pure-white;
            border-radius: 50%;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);

            font-family: $font-medium;
            font-size: 0.55rem;
            text-transform: uppercase;

            cursor: default;
            transition: opacity 0.2s ease-in-out;
            opacity: 0.5;

            &:not(.active):hover {
                cursor: pointer;
                opacity: 1;
            }

            &.active {
                opacity: 1;
            }
        }
    }
}
