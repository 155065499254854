@import '../../styles/variables';

.Loader {
    width: 50px;
    height: 50px;
    border: 5px solid transparent;
    border-top-color: $color-jcp-red;
    border-right-color: $color-jcp-red;
    border-bottom-color: $color-jcp-red;
    border-radius: 50%;
    animation: rotate360 .75s linear infinite;
}

@keyframes rotate360 {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(1turn);
    }
}