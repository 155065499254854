$main-container-width: 1920px;
$main-container-width-min: 1024px;
$main-container-height: 1080px;

// COLORS
$color-jcp-red: #e71324;
$color-jcp-red-light: #fa443b;
$color-pure-white: #ffffff;
$color-almost-white: #fdfdfd;
$color-light-cream: #f2ece4;
$color-diet-cream: #f6f1ea;
$color-cream: #f8f5ee;
$color-sand: #ece7e2;
$color-soft-gray: #e2e2e2;
$color-almost-black: #24272a;
$color-pure-black: #000000;
$color-mid-grey: #36322d;
$color-mid-greige: #8b8179;
$color-light-greige: #d0ccbd;
$color-very-light-greige: #eee8e0;
$color-chartreuse: #e0e61d;
$color-light-greige-alpha: rgba(136, 128, 122, 0.1);

// TYPOGRAPHY
$font-bold: 'Moderat-Bold', sans-serif;
$font-condensed-thin: 'Moderat-Condensed-Thin', sans-serif;
$font-extended-medium: 'Moderat-Extended-Medium', sans-serif;
$font-light: 'Moderat-Light', sans-serif;
$font-medium: 'Moderat-Medium', sans-serif;
$font-regular: 'Moderat-Regular', sans-serif;
$font-thin: 'Moderat-Thin', sans-serif;

// SPACING
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 64px;
$space-xxl: 128px;
$space-default: $space-m;
$space-inset-default: $space-m $space-m $space-m $space-m;
$space-inset-xs: $space-xs $space-xs $space-xs $space-xs;
$space-inset-s: $space-s $space-s $space-s $space-s;
$space-inset-m: $space-m $space-m $space-m $space-m;
$space-inset-l: $space-l $space-l $space-l $space-l;
$space-inset-xl: $space-xl $space-xl $space-xl $space-xl;
$space-stack-default: 0 0 $space-m 0;
$space-stack-xs: 0 0 $space-xs 0;
$space-stack-s: 0 0 $space-s 0;
$space-stack-m: 0 0 $space-m 0;
$space-stack-l: 0 0 $space-l 0;
$space-stack-xl: 0 0 $space-xl 0;

// BORDERS
$border-width: 7px;
$border-circle-width: 3px;

// ANIMATIONS
$transition-duration: .3s;
$transition-duration-alt: .1s;
$transition-timing: ease;
$cubic-bezier: cubic-bezier(0.215, 0.61, 0.355, 1);

// ELEMENTS
$header-height: 10vh;
$layout-padding-v: 40px;
$layout-padding-h: 3vw;
$inspired-height: 40px;
$button-height-lg: 70px;
$button-height: 63px;
$button-circle-height: 45px;

// Z-Index
$z-index-modal-layer: 9001;
$z-index-header-layer: 1000;
