@import '../../../../styles/variables';

.AgeGateModal {
    width: 100%;
    min-width: 35rem;
    padding: 4.5rem;
}

.AgeGateModal h2 {
    font-size: 1.25rem;
    margin-bottom: $space-xxl;
    line-height: 1.25;
}

.AgeGateModal .buttonWrapper {
    display: flex;
    justify-content: space-around;
}

.AgeGateModal .buttonWrapper button {
    flex: 0 0 38%;
}

.cancelButton span:nth-of-type(2):before {
    border-left: 8px solid $color-almost-black;
}
